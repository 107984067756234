<header class="header">
  <div class="cont">
    <div class="upper-header">
      <a href="tel:8(017)396-36-25">8(017)396-36-25</a>
      <a href="mailto:director@college-ripo.by">director@college-ripo.by</a>
    </div>
  </div>
    <div class="lower-header">
      <div id="nav-button" class="nav-button">
        <button (click)="showMenu()" mat-mini-fab color="primary" id="burger"><mat-icon>menu</mat-icon></button>
      </div>
      <div class="cont">
        <ul id="menu" class="horizontal-menu">
          <mat-select [(value)]="selected" panelClass="lang-option" class="" (selectionChange)="changeLanguage()">
            <mat-select-trigger class="">
              <span class="selected">{{selected.value}}</span>
              <img src="assets/chevron-down.svg" alt="">
            </mat-select-trigger>
            <mat-option *ngFor="let lang of langSelections" [value]="lang">
              <span class="ps-2">{{lang.value}}</span>
            </mat-option>
          </mat-select>
          <li (click)="navigateTo('')">
            <button mat-button>
              {{ 'header.main.top'| translate}}
            </button>
          </li>
          <li>
            <button mat-button [matMenuTriggerFor]="about_college">
              {{ 'header.about-college.top'| translate}}
              <img src="assets/chevron-down.svg" alt="">
            </button>
            <mat-menu #about_college="matMenu">
              <button mat-menu-item (click)="navigateTo('about-college/administration')">{{ 'header.about-college.0'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/19OcJv_Txo6RFws-acR9rz9Xjeojs5SzL/view')">{{ 'header.about-college.1'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1w6EVfmc99mAHXJsXAdLOaZKPwnV_DE2e/view')">{{ 'header.about-college.2'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1Id0Pbm4unyy9K_CdqZRsMHpDOmwz2HB0/view')">{{ 'header.about-college.3'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('about-college/local-acts')">{{ 'header.about-college.4'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('about-college/personal')">{{ 'header.about-college.5'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('about-college/anti-corruption')">{{ 'header.about-college.6'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('about-college/disabled')">{{ 'header.about-college.7'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('about-college/safety')">{{ 'header.about-college.8'| translate}}</button>
            </mat-menu>
          </li>
          <li (click)="navigateTo('news')">
            <button mat-button>
              {{ 'header.news'| translate}}
            </button>
          </li>
          <li>
            <button mat-button [matMenuTriggerFor]="trainees">
              {{ 'header.trainees.top'| translate}}
              <img src="assets/chevron-down.svg" alt="">
            </button>
            <mat-menu #trainees="matMenu">
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1cgxL1_zlXBYpfwMrVTIbGAOTLCvxwd8a/view')">{{ 'header.trainees.0'| translate}}</button>

              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1gdkLkI4WRlYahaCNdkGfUyUvsEfQeIJK/view')">{{ 'header.trainees.1'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('trainees/announcement')">{{ 'header.trainees.2'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('trainees/specialties')">{{ 'header.trainees.3'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('trainees/t-training')">{{ 'header.trainees.4'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('trainees/professional')">{{ 'header.trainees.5'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('trainees/regulations')">{{ 'header.trainees.6'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/19Xj5tgON6Z_DasIvsR-F7IFWC_AvlEMg/view')">{{ 'header.trainees.7'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://profitest.ripo.by/public/main')">{{ 'header.trainees.8'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('trainees/contacts-applicant')">{{ 'header.trainees.9'| translate}}</button>
            </mat-menu>
          </li>
          <li>
            <button mat-button [matMenuTriggerFor]="students">
              {{ 'header.students.top'| translate}}
              <img src="assets/chevron-down.svg" alt="">
            </button>
            <mat-menu #students="matMenu">
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1a3phLdSWU7HZPeT0UH9FBch1sbFm_0gC/view')">{{ 'header.students.0'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1eWn6wPwL-u5yyJm5x-my5vQDXODNQZzT/view')">{{ 'header.students.1'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1Cl20yidovQIpBJ9On5Jh-46Tu5Br_WY-/view')">{{ 'header.students.2'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1uIXWTukVvVmMWObD84eA1Zx8MC63acH2/view')">{{ 'header.students.3'| translate}}</button>
            </mat-menu>
          </li>
          <li>
            <button mat-button [matMenuTriggerFor]="teachers">
              {{ 'header.teachers.top'| translate}}
              <img src="assets/chevron-down.svg" alt="">
            </button>
            <mat-menu #teachers="matMenu">
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1UxUEXpOdNcia5PDk2CwHmk_00NaNXqYr/view')">{{ 'header.teachers.0'| translate}}</button>
              <button mat-menu-item >{{ 'header.teachers.1'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://web.telegram.org/k/#@metod_ripo')">{{ 'header.teachers.2'| translate}}</button>
              <button mat-menu-item>{{ 'header.teachers.3'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1iAjrIosepAbqdfKJTqCNHYEvcpkw4xia/view')">{{ 'header.teachers.4'| translate}}</button>
              <button mat-menu-item>{{ 'header.teachers.5'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1Cl20yidovQIpBJ9On5Jh-46Tu5Br_WY-/view')">{{ 'header.teachers.6'| translate}}</button>
              <button mat-menu-item>{{ 'header.teachers.7'| translate}}</button>
            </mat-menu>
          </li>
          <li>
            <button mat-button [matMenuTriggerFor]="upbringing">
              {{ 'header.upbringing.top'| translate}}
              <img src="assets/chevron-down.svg" alt="">
            </button>
            <mat-menu #upbringing="matMenu">
              <button mat-menu-item (click)="navigateTo('upbringing/self-management')">{{ 'header.upbringing.0'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/legal-corner')">{{ 'header.upbringing.1'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/step')">{{ 'header.upbringing.2'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/abcsafety')">{{ 'header.upbringing.3'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/volunteer-movement')">{{ 'header.upbringing.4'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://president.gov.by/ru/events/2025-god-ob-avlen-godom-blagoustrojstva')">{{ 'header.upbringing.5'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/travel')">{{ 'header.upbringing.6'| translate}}</button>
              <button mat-menu-item (click)="openSite('https://sites.google.com/view/belarus-hatka/%D0%B3%D0%B0%D0%BB%D0%BE%D1%9E%D0%BD%D0%B0%D1%8F-%D1%81%D1%82%D0%B0%D1%80%D0%BE%D0%BD%D0%BA%D0%B0')">{{ 'header.upbringing.7'| translate}}</button>
              <button mat-menu-item [matMenuTriggerFor]="psyco">{{ 'header.upbringing.8'| translate}}</button>
              <button mat-menu-item [matMenuTriggerFor]="psyc">{{ 'header.upbringing.9'| translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/military-patriotic-education')">{{ 'header.upbringing.10'| translate}}</button>
              <button mat-menu-item [matMenuTriggerFor]="rab">{{ 'header.upbringing.11'| translate}}</button>
              

            
            </mat-menu>
            <mat-menu #psyco="matMenu">
              <button mat-menu-item (click)="navigateTo('upbringing/spps-ych')">{{'header.upbringing-psycho.0'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/spps-rod')">{{'header.upbringing-psycho.1'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/spps-ped')">{{'header.upbringing-psycho.2'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/spps-kyr')">{{'header.upbringing-psycho.3'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('upbringing/help')">{{'header.upbringing-psycho.4'|translate}}</button>
            </mat-menu>

          <mat-menu #psyc="matMenu">
            <button mat-menu-item (click)="navigateTo('upbringing/life')">{{'header.upbringing-Events.0'|translate}}</button>
          </mat-menu>

          <mat-menu #rab="matMenu">
            <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/18uAdg7nBZbVGHZS1_wxZNNQ9Ss22TFqB/view')">{{ 'header.upbringing-rab.0'| translate}}</button>
            <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/12qWhA1RAyU-ZXbj55Ons6HfE6H7ug0qb/view')">{{ 'header.upbringing-rab.1'| translate}}</button>
            <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1-CCNv73SvcX_X67XpeVYAjLYPGWzCheM/view')">{{ 'header.upbringing-rab.2'| translate}}</button>
            <button mat-menu-item (click)="openSite('https://drive.google.com/file/d/1SM3tSSr7Ayq79h-n-YMpCBDUSiupTku-/view')">{{ 'header.upbringing-rab.3'| translate}}</button>
          </mat-menu>
          </li>
          <li>
            <button mat-button [matMenuTriggerFor]="services">
              {{ 'header.services.top'| translate}}
              <img src="assets/chevron-down.svg" alt="">
            </button>
            <mat-menu #services="matMenu">
              <button mat-menu-item (click)="openSite('http://aschool.kstmiavd.by/about/')">{{ 'header.services.0'| translate}}</button>
              <button mat-menu-item (click)="openSite('http://fok.kstmiavd.by/')">{{ 'header.services.1'| translate}}</button>
            </mat-menu>
          </li>
          <li>
            <button mat-button [matMenuTriggerFor]="singlewindow">
              {{ 'header.singlewindow.top'| translate}}
              <img src="assets/chevron-down.svg" alt="">
            </button>
            <mat-menu #singlewindow="matMenu">
              <button mat-menu-item (click)="navigateTo('singlewindow/schedule-kstmia')">{{'header.singlewindow.0'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('singlewindow/schedule-ripo')">{{'header.singlewindow.1'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('singlewindow/documentation')">{{'header.singlewindow.2'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('singlewindow/payment')">{{'header.singlewindow.3'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('singlewindow/certificates')">{{'header.singlewindow.4'|translate}}</button>
              <button mat-menu-item (click)="navigateTo('singlewindow/contacts-kstmia')">{{'header.singlewindow.5'|translate}}</button>
            </mat-menu>
          </li>
          <li>
            <button class="blind-button" (click)="changeFont()">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
          </li>
        </ul>
      </div>
    </div>

  </header>
