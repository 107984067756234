<div class="cont">
    <H2 class="head">{{'military-patriotic-education.head'|translate}}</H2>
 
</div>

<div class="cont">

    <div class="roww">
        <img src="assets/administration/Poscrebko.png" alt="Фотография" >

    </div>
</div>
<div class="cont">
    <p class="head5" [innerHTML]="'military-patriotic-education.text' | translate"></p>


</div>
<docs-table [translations]="translations"></docs-table>
<div class="cont">


    <p [innerHTML]="'military-patriotic-education.text4' | translate"></p>
<div class="display-row">
    <img src="assets/components/News25/23-01-2025-22.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-23.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-24.png" alt="Фотография" width="600" height="500">
</div>

    <p [innerHTML]="'military-patriotic-education.text1' | translate"></p>
<div class="display-row">
    <img src="assets/components/News25/19-01-2025-1.png" alt="Фотография" width="900" height="500">
    <img src="assets/components/News25/19-01-2025-2.png" alt="Фотография" width="900" height="500">
</div>

<p [innerHTML]="'military-patriotic-education.text2' | translate"></p>
<div class="display-row">
    <img src="assets/components/News25/17-01-2025-6.png" alt="Фотография" width="700" height="500">
    <img src="assets/components/News25/17-01-2025-7.png" alt="Фотография" width="700" height="500">
    <img src="assets/components/News25/17-01-2025-8.png" alt="Фотография" width="700" height="500">
</div>

<p [innerHTML]="'military-patriotic-education.text3' | translate"></p>
<div class="display-row">
    <img src="assets/components/News25/15-01-2025-4.png" alt="Фотография" width="750" height="500">
    <img src="assets/components/News25/15-01-2025-5.png" alt="Фотография" width="600" height="500">
    <img src="assets/components/News25/15-01-2025-6.png" alt="Фотография" width="400" height="500">
</div>
</div>

