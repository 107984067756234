import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from 'src/app/components/docs-table/docs-table.component';

@Component({
  selector: 'app-military-patriotic-education',
  standalone: true,
  imports: [CommonModule, DocsTableComponent, TranslateModule],
  templateUrl: './military-patriotic-education.component.html',
  styleUrls: ['./military-patriotic-education.component.sass']
})
export class MilitaryPatrioticEducationComponent {
  translations: string = 'military-patriotic-education';
  customOptions: any;
}
