<div class="cont">
    <H2 class="head">{{'news.head'|translate}}</H2>
</div>  
    <docs-table [translations]="translations"></docs-table>
<div class="cont">


    <div class="display-row">
        <img src="assets/components/News24/vibori.png" alt="Фотография" width="1000" height="500">

    </div>



    <p [innerHTML]="'news.text62' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-22.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-23.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-24.png" alt="Фотография" width="600" height="500">
    </div>
    <p [innerHTML]="'news.text61' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-19.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-20.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-21.png" alt="Фотография" width="400" height="500">
    </div>
    <p [innerHTML]="'news.text60' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-16.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-17.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-18.png" alt="Фотография" width="400" height="500">
    </div>
    <p [innerHTML]="'news.text59' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-13.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-14.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-15.png" alt="Фотография" width="400" height="500">
    </div>
    <p [innerHTML]="'news.text58' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-12.png" alt="Фотография" width="400" height="500">
    </div>
    <p [innerHTML]="'news.text57' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-9.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-10.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-11.png" alt="Фотография" width="800" height="500">
    </div>
    <p [innerHTML]="'news.text56' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-6.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-7.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-8.png" alt="Фотография" width="800" height="500">
   </div>

    <p [innerHTML]="'news.text55' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-4.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-5.png" alt="Фотография" width="800" height="500">
    </div>

    <p [innerHTML]="'news.text54' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-3.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text53' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/22-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/22-01-2025-2.png" alt="Фотография" width="650" height="500">
        <img src="assets/components/News25/22-01-2025-3.png" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text52' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-25.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-26.png" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text51' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-22.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-23.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-24.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text50' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-20.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-21.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text49' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-18.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-19.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text48' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-15.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/21-01-2025-16.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/21-01-2025-17.png" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text47' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-12.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-13.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-14.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text46' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-10.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-11.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text45' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-8.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-9.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text44' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-5.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/21-01-2025-6.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/21-01-2025-7.png" alt="Фотография" width="600" height="500">


    </div>

    <p [innerHTML]="'news.text43' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-3.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-4.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text42' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-01-2025-3.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/20-01-2025-4.png" alt="Фотография" width="800" height="500">

    </div>

    <p [innerHTML]="'news.text41' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-01-2025-2.png" alt="Фотография" width="450" height="500">
    </div>

    <p [innerHTML]="'news.text40' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-01-2025-1.png" alt="Фотография" width="400" height="600">
    </div>

    <p [innerHTML]="'news.text39' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/19-01-2025-1.png" alt="Фотография" width="900" height="500">
        <img src="assets/components/News25/19-01-2025-2.png" alt="Фотография" width="900" height="500">
    </div>

    <p [innerHTML]="'news.text38' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/17-01-2025-6.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/17-01-2025-7.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/17-01-2025-8.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text37' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/17-01-2025-4.png" alt="Фотография" width="900" height="500">
        <img src="assets/components/News25/17-01-2025-5.png" alt="Фотография" width="700" height="500">


    </div>

    <p [innerHTML]="'news.text36' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/17-01-2025-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/17-01-2025-2.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/17-01-2025-3.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text35' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/16-01-2025-11.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-12.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-13.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text34' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/16-01-2025-8.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/16-01-2025-9.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/16-01-2025-10.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text33' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/16-01-2025-4.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-5.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-7.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text32' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/16-01-2025-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-2.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-3.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text31' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/15-01-2025-4.png" alt="Фотография" width="750" height="500">
        <img src="assets/components/News25/15-01-2025-5.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/15-01-2025-6.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text30' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/14-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/14-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/14-01-2025-3.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text29' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/12-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/12-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/12-01-2025-3.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text28' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/15-01-2025-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/15-01-2025-2.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/15-01-2025-3.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text27' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/13-01-2025-1.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/News25/13-01-2025-2.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/News25/13-01-2025-3.png" alt="Фотография" width="500" height="500">

    </div>

    <p [innerHTML]="'news.text26' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/11-01-2025-4.png" alt="Фотография" width="800" height="500">

    </div>

    <p [innerHTML]="'news.text25' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/11-01-2025-3.png" alt="Фотография" width="800" height="500">

    </div>

    <p [innerHTML]="'news.text24' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/11-01-2025-1.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/11-01-2025-2.png" alt="Фотография" width="800" height="500">



    </div>

    <p [innerHTML]="'news.text23' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/10-01-2025-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/10-01-2025-2.png" alt="Фотография" width="700" height="500">



    </div>

    <p [innerHTML]="'news.text22' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/09-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/09-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/09-01-2025-3.png" alt="Фотография" width="400" height="500">


    </div>

    <p [innerHTML]="'news.text21' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/08-01-2025-4.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/News25/08-01-2025-6.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/News25/08-01-2025-7.png" alt="Фотография" width="500" height="500">


    </div>

    <p [innerHTML]="'news.text20' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/08-01-2025-1.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/08-01-2025-2.png" alt="Фотография" width="700" height="500">


    </div>

    <p [innerHTML]="'news.text19' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/08-01-2025-1.png" alt="Фотография" width="1000" height="500">
        <img src="assets/components/News24/08-01-2025-2.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/08-01-2025-3.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text18' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/31-12-2024-1.png" alt="Фотография" width="1000" height="500">
        <img src="assets/components/News24/31-12-2024-2.png" alt="Фотография" width="600" height="500">
    </div>
    
    <p [innerHTML]="'news.text17' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/28-12-2024-7.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/28-12-2024-8.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/28-12-2024-9.png" alt="Фотография" width="600" height="500">



    </div>

    <p [innerHTML]="'news.text16' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/28-12-2024-4.png" alt="Фотография" width="600" height="500">

        <img src="assets/components/News24/28-12-2024-5.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/28-12-2024-6.png" alt="Фотография" width="400" height="500">



    </div>

    <p [innerHTML]="'news.text15' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/28-12-2024-1.png" alt="Фотография" width="400" height="500">

        <img src="assets/components/News24/28-12-2024-2.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News24/28-12-2024-3.png" alt="Фотография" width="400" height="500">



    </div>


    <p [innerHTML]="'news.text14' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/27-12-2024-3.png" alt="Фотография" width="600" height="500">

        <img src="assets/components/News24/27-12-2024-5.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News24/27-12-2024-6.png" alt="Фотография" width="600" height="500">



    </div>

    <p [innerHTML]="'news.text13' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/27-12-2024-1.png" alt="Фотография" width="350" height="500">
        <img src="assets/components/News24/27-12-2024-2.png" alt="Фотография" width="1000" height="500">



    </div>




   







 
</div>