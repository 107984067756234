<div class="cont">
    <H2 class="head">{{'mspo.head'|translate}}</H2>
    
</div>

<div class="cont">

    <div class="roww">
        <img src="assets/administration/bacshtaev.png" alt="Фотография" width="250" height="320">
    </div>
</div>


<div class="cont">
    <p class="head5" [innerHTML]="'mspo.text' | translate"></p>
    <docs-table [translations]="translations"></docs-table>
</div>

<p class="head1" [innerHTML]="'oi-ra.text1' | translate"></p>

<div class="contt">
    <table >
        <tbody>
            <tr>
                <td rowspan="2">СПЕЦИАЛЬНОСТЬ</td>
                <td>2-36 01 33</td>
                <td>Эксплуатация мехатронных систем промышленного
    
    оборудования</td>
            </tr>
            <tr>
                <td>5-04-0714-17</td>
                <td>Эксплуатация мехатронных систем промышленного
    
    оборудования</td>
            </tr>
            <tr>
                <td>СРОК ПОЛУЧЕНИЯ
    ОБРАЗОВАНИЯ</td>
                <td colspan="2">в дневной форме на основе общего базового образования – 3 года 10 месяцев
    в дневной форме на основе общего среднего образования – 2 года 10 месяцев</td>
            </tr>
            <tr>
                <td>КВАЛИФИКАЦИЯ
    СПЕЦИАЛИСТА</td>
                <td colspan="2">Техник-мехатроник</td>
            </tr>
            <tr>
                <td rowspan="3">КВАЛИФИКАЦИИ
    РАБОЧЕГО</td>
                <td colspan="2">Оператор станков с программным управлением 3 - 4 разряда</td>
            </tr>
            <tr>
                <td colspan="2">Наладчик станков и манипуляторов с программным управлением 4-го разряда</td>
            </tr>
            <tr>
                <td colspan="2">Мехатроник 5-го разряда</td>
            </tr>
        </tbody>
    </table>
</div>
<p class="head1" [innerHTML]="'oi-ra.text2' | translate"></p>

<p class="head1" [innerHTML]="'pto.text2' | translate"></p>


<div class="contt">
    <table >
        <tbody>
            <tr>
                <td rowspan="2">Курс</td>
                <td rowspan="2">№ группы</td>
                <td colspan="2">Количество учащихся</td>
                <td rowspan="2">Количество
    вакантных мест</td>
                <td rowspan="2">Куратор</td>
            </tr>
            <tr>
                <td>на момент
    поступления</td>
                <td>на 01 февраля 2025г.</td>
            </tr>
            <tr>
                <td rowspan="2">I</td>
                <td>451</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Томилина Галина Леонидовна</td>
            </tr>
            <tr>
                <td>452</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Попов Артем Константинович</td>
            </tr>
            <tr>
                <td rowspan="2">II</td>
                <td>351</td>
                <td>27</td>
                <td>27</td>
                <td>0</td>
                <td>Галуза Людмила Александровна</td>
            </tr>
            <tr>
                <td>352</td>
                <td>27</td>
                <td>27</td>
                <td>0</td>
                <td>Федосевич Елена Михайловна</td>
            </tr>
            <tr>
                <td rowspan="3">III</td>
                <td>353</td>
                <td>27</td>
                <td>24</td>
                <td>3</td>
                <td>Васько Екатерина Николаевна</td>
            </tr>
            <tr>
                <td>251</td>
                <td>27</td>
                <td>27</td>
                <td>0</td>
                <td>Макаревич Оксана Александровна</td>
            </tr>
            <tr>
                <td>252</td>
                <td>27</td>
                <td>25</td>
                <td>2</td>
                <td>Михейчик Елена Николаевна</td>
            </tr>
            <tr>
                <td rowspan="3">IV</td>
                <td>253</td>
                <td>26</td>
                <td>22</td>
                <td>4</td>
                <td>Соловей Игорь Александрович</td>
            </tr>
            <tr>
                <td>151</td>
                <td>27</td>
                <td>25</td>
                <td>2</td>
                <td>Синица Павел Викторович</td>
            </tr>
            <tr>
                <td>152</td>
                <td>27</td>
                <td>25</td>
                <td>2</td>
                <td>Бардушка Светлана Антоновна</td>
            </tr>
            <tr>
                <td></td>
                <td><strong>ИТОГО:</strong></td>
                <td><strong>271</strong></td>
                <td><strong>258</strong></td>
                <td><strong>13</strong></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>

<p class="head1" [innerHTML]="'mspo.text1' | translate"></p>

<div class="cont">

    <div class="display-row">
        <img src="assets/components/otdelenia/tex-mexBacsh.png" alt="Фотография" width="700" height="1000">
    </div>
</div>